@import "src/utils/colours";

.ProjectDetail {
  padding: 20px;
  text-align: center;

  .Title {
    font-size: xx-large;
    padding: 16px;
    background-color: $colour-purple;
    border: 2px solid black;
    color: white;
    box-shadow: 10px 10px $colour-purple;
  }

  .DetailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    margin-top: 16px;
    padding-bottom: 8px;
    background-color: $colour-pink;
    box-shadow: 10px 10px $colour-pink;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    iframe {
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .Description {
      width: 80%;
      text-align: justify;

      @media only screen and (max-width: 600px) {
        width: 90%;
      }
    }

    a {
      text-decoration: none;
      border: 2px solid black;
      color: black;
      padding: 4px;

      @media only screen and (max-width: 600px) {
        margin: 8px;
        padding: 2px;
      }

    }

    a:hover {
      color: white;
      background-color: $colour-pink;
      border: 2px solid white;
    }
  }
}
@import "src/utils/colours";

.Home {

  .Skills {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .SkillsContainer {
      display: flex;
      flex-direction: column;
      width: 30%;

      @media only screen and (max-width: 600px) {
        width: 90%;
        padding: 8px;
      }

      .SkillsImage {
        border: 2px solid $colour-light-green;
        box-shadow: 8px 8px $colour-light-green;
        margin: 8px 16px;
        object-fit: cover;
        width: 90%;
        height: 200px;
      }

      .SkillsName {
        font-size: larger;
        text-align: center;
      }
    }
  }

  .Title {
    text-align: center;
    font-size: x-large;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: 90%;
      padding: 8px;
    }
  }

  .Panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }

    .HomeLogo {
      color: $colour-purple;
      border: 2px solid $colour-purple;
      height: 20%;
      width: 75%;
      font-size: 10vw;
      text-align: center;
      margin: 16px;
      box-shadow: 8px 8px $colour-purple;
    }
  }

  .Technologies {
    border: 2px solid black;
    max-width: 60%;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    padding: 4px;
    box-shadow: 10px 10px black;
    align-items: center;

    @media only screen and (max-width: 600px) {
      max-width: 90%;
      padding: 8px;
      text-align: center;
      flex-direction: column;
    }

    .TechnologiesSection {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .TechUsed {
      font-size: large;
    }

    .TechIcons {
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: space-evenly;
      padding: 16px;

      .TechImage {
        max-width: 20%;
        padding: 8px;

        @media only screen and (max-width: 600px) {
          max-width: 40%;
        }
      }
    }
  }
}
@import "src/utils/colours";

.Content {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    padding: 10px;
    flex-direction: column;
  }

  .Sidebar {
    width: 25%;
    border: 2px solid $colour-purple;
    box-shadow: 10px 10px $colour-purple;
    padding: 8px;
    height: fit-content;

    @media only screen and (min-width: 600px) {
      position: -webkit-sticky;
      position: sticky;
      top: 10px;
    }

    @media only screen and (max-width: 600px) {
      margin: 0 8px 16px 8px;
      width: auto;
    }
  }

  .Posts {
    width: 70%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .PostLinkContainer {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .PostLinks {
    background: none;
    color: #bdafd6;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}
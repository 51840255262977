@import "src/utils/colours";

.BlogPost {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: flex-start;
  border: 2px solid $colour-pink;
  box-shadow: 10px 10px $colour-pink;
  margin: 0 8px 20px 8px;


  .Title {
    font-size: x-large;
    text-align: left;
  }

  .Date {
    font-size: small;
    text-align: left;
  }

  .Post {
    text-align: justify;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  table td, table th {
    border: 2px solid $colour-green;
    padding: 4px 4px;
  }

  table thead th {
    font-weight: bold;
    color: $colour-green;
  }
}
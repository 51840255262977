@import "src/utils/colours";

.Home {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 16px;

  .Title {
    font-size: xxx-large;
    float: start;
  }

  .SectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 32px;


    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }

    .About {
      max-width: 60%;
      text-align: justify;
      border: 2px solid black;
      padding: 8px;
      box-shadow: 10px 10px $colour-pink;
      background: $colour-pink;

      @media only screen and (max-width: 600px) {
        max-width: 100%;
      }

      h3 {
        margin-top: 0;
        padding: 4px;
        border-bottom: 2px solid $colour-background;

        @media only screen and (max-width: 600px) {
          align-self: flex-start;
        }
      }
    }

    .AboutImage {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      text-align: center;

      img {
        max-height: 150px;
        border: 2px solid $colour-background;
      }
    }

    .Links {
      display: flex;
      flex-direction: column;
      max-width: 30%;
      border: 2px solid black;
      padding: 8px;
      box-shadow: 10px 10px $colour-purple;
      background-color: $colour-purple;
      height: fit-content;


      @media only screen and (max-width: 600px) {
        margin-top: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: space-around;
      }

      a {
        color: #282c34;
        text-decoration: none;
        font-size: 18px;
        padding: 16px 16px 16px 16px;
        align-items: center;
        display: flex;
        border: 2px solid white;
        margin: 4px 0;

        @media only screen and (max-width: 600px) {
          flex-grow: 1;
          margin: 4px;
        }

        img {
          max-height: 20px;
          padding-right: 5px;
        }
      }

      a:hover {
        color: white;
      }
    }

    .BodyContainer {
      display: flex;
      flex-direction: row-reverse;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    .Body {
      border: 2px solid $colour-pink;
      padding: 4px;
      margin: 0 4px 4px 0;
      text-indent: 20px;

      p {
        margin: 0
      }
    }
  }

}
.Projects {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 24px;

  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
}

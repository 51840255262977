@import "src/utils/colours";

.ProjectSummary {
  max-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid black;
  margin: 16px;
  box-shadow: 10px 10px black;
  padding: 0 0 24px 0;

  .Title {
    font-size: 24px;
    margin-bottom: 16px;
    width: 100%;
    text-indent: 4px;
    text-align: left;
    border-bottom: 1px solid black;
    background-color: $colour-light-green;
  }

  .Body {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .Image {
    height: 300px;
    width: 250px;
    object-fit: cover;
    border: 2px solid black;
  }

  .Icon {
    text-align: center;
    padding: 16px;

    img {
      max-height: 100px;
      max-width: 80%;
    }
  }

  .Summary {
    text-align: justify;
    height: 120px;
  }

  .LearnMore {
      margin-top: 16px;
    a {
      padding: 6px;
      color: black;
      text-decoration: none;
      border: 2px solid black;
      background-color: $colour-pink;
    }

    a:hover {
      color: white;
      background-color: $colour-purple;
      border: 2px solid white;
    }
  }

}